<template>
  <v-dialog :model-value="value" persistent max-width="480">
    <v-card data-cy="dialog_leave_page">
      <v-card-title>
        {{ $t('global.leave_site.title') }}
      </v-card-title>
      <v-card-text>
        {{ $t('global.leave_site.text') }}
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn size="x-large" color="primary" variant="outlined" @click="stay()">
          {{ $t('global.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn size="x-large" color="primary" variant="elevated" @click="leave()">
          {{ $t('global.leave_site.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
export default defineComponent({
  name: 'DialogLeavePage',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    leave() {
      this.$emit('leave')
    },
    stay() {
      this.$emit('stay')
    },
  },
})
</script>

<style lang="sass"></style>
